<template>
  <div class="main">
    <h3>最新{{dtypeS}}价格: {{newPrice}}元/克</h3>
    <div class="select">
      <div class="select-div">
        <a :href="urls.day" rel="noopener noreferrer" class="my-select" :class="{'my-select-active': ttype == 'day'}">日线</a>
      </div>
      <div class="select-div">
        <a :href="urls.week" rel="noopener noreferrer" class="my-select" :class="{'my-select-active': ttype == 'week'}">周线</a>
      </div>
      <div class="select-div">
        <a :href="urls.month" rel="noopener noreferrer" class="my-select" :class="{'my-select-active': ttype == 'month'}">月线</a>
      </div>
    </div>
    <div id="kline">
    </div>
    <span class="tooltip-note">图上停留2秒,可使用滑动十字标</span>
  </div>
</template>

<script>
import {dispose, init} from 'klinecharts'
import axios from "axios";
import datos from "../apps.js"
export default {
    name: 'Price',
    props: ['type', 'ttype'],
    data() {
        return {
            types: {
                'gd': 0, 'si': 1, 'pl': 2, 'pa': 3,
                'nygd': 4, 'nysi': 5, 'nypl': 6, 'nypa': 7,
                'rmbdol': 40, 'rmbeu': 41},
            ttypes: {'day': 1, 'week': 2, 'month': 3,},
            hasOptionChart: false,
            dtypeS: {},
            chart: null,
            page: 0,
            hasMore: true,
            limit: 0,
            num: 0,
            newPrice: 0,
            urls: {
                'day':"/price/" + this.type + "/day.html",
                'week':"/price/" + this.type + "/week.html",
                'month':"/price/" + this.type + "/month.html",
            }
        }
    },
    mounted() {
        this.num = Math.floor(Math.random()*40)
        this.initChart()
        this.getData(false)
        this.dtypeS = {
            0: '黄金', 1: '白银', 2: '铂金', 3: '钯金',
            4: '纽约黄金', 5: '纽约白银', 6:'纽约铂金', 7:'纽约钯金',
            40: '美元兑人民币', 41:'欧元兑人民币'}[this.types[this.type]]
    },
    beforeUnmount() {
        //组件销毁前先销毁 ECharts 实例
        if (!this.chart) { return }
        dispose('kline')
    },
    methods: {
        initChart(){
        if (this.chart){
            dispose('kline')
        }
        this.chart = init('kline')
        this.chart.loadMore(timestamp => {
            console.log("timestamp:"+ timestamp);
            setTimeout(() => {
            this.getMoreData()
            }, 300)})
        },
        changeData(){
            this.hasOptionChart = false
            this.limit = 0
            this.hasMore = true
            this.page = 0
            this.initChart()
            this.getData(false)
        },
        getMoreData(){
            this.getData(true)
        },
        getData(addmore){
            if (!this.hasMore) {
                return
            }
            axios
            .get("/" + datos()[this.num].desc + "/v1/futures/price/list", {params: {
                dtype: this.types[this.type], ttype: this.ttypes[this.ttype], page: this.page}})
            .then((data) => {
                if (data.data.code == 200) {
                    var priceData = []
                    for (let index = data.data.data.length - 1; index > -1; index--) {
                        var elem = data.data.data[index]
                        priceData.push({
                        close: elem.end_price, high: elem.max_price,
                        low: elem.min_price, open: elem.start_price,
                        timestamp: elem.timestamp*1000})
                    }
                    if (this.page == 0) {
                        this.newPrice = priceData[priceData.length - 1].close
                    }
                    this.page += 1
                    if (priceData.length > this.limit) {
                        this.limit = priceData.length
                    }
                    if (this.limit > priceData.length || priceData.length == 0 || this.page >= 3) {
                        this.hasMore = false
                    }

                    if (!this.hasOptionChart) {
                        this.optionChart()
                    }
                    if (!addmore) {
                        this.chart.applyNewData(priceData, this.hasMore)
                        
                    }else{            
                        this.chart.applyMoreData(priceData, this.hasMore)
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            })
        },
        optionChart(){
            this.hasOptionChart = true
            this.chart.setStyleOptions({
                candle: {
                    bar: {
                        upColor: '#EF5350',
                        downColor: '#26A69A',
                        noChangeColor: '#888888'
                    },
                    margin: {
                        top: 0.1,
                        bottom: 0.05
                    },
                    tooltip: {
                        showRule: 'follow_cross',
                        labels: ['开', '收', '高', '低', '涨幅 '],
                        showType: 'rect',
                        values: kLineData => {
                        const change = (kLineData.close - kLineData.open) / kLineData.open * 100
                        return [
                            { value: kLineData.open.toFixed(2) },
                            { value: kLineData.close.toFixed(2) },
                            { value: kLineData.high.toFixed(2) },
                            { value: kLineData.low.toFixed(2) },
                            {
                            value: `${change.toFixed(2)}%`,
                            color: change > 0 ? '#EF5350' : '#26A69A'
                            }
                        ]
                        }
                    },
                },
                yAxis:{
                    show: false,
                    inside: true,
                },
            })
            this.chart.setOffsetRightSpace(2)
            if (document.body.clientWidth < 768) {
                this.chart.setDataSpace(8)
                this.chart.setRightMinVisibleBarCount(20)
                this.chart.setLeftMinVisibleBarCount(30)
            }else if(document.body.clientWidth < 1100){
                this.chart.setDataSpace(10)
                this.chart.setRightMinVisibleBarCount(30)
                this.chart.setLeftMinVisibleBarCount(60)
            }else{
                this.chart.setDataSpace(10)
                this.chart.setRightMinVisibleBarCount(80)
                this.chart.setLeftMinVisibleBarCount(90)
            }
        }
    }
}
</script>
<style>
    a{
        cursor: pointer;
        text-decoration: none;
        color: #666;
    }
    .select{
        display: flex;
        width: 100%;
        padding-top: 1rem;
        position: relative;
    }
    .select-div {
        width: 33%;
    }
    .my-select{
        background-color: #fff;
        border: 1px solid #dfdfdf;
        border-radius: 15%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 3.5rem;
        height: 1.5rem;
        box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    }
    .my-select-active{
        background-color: rgb(221, 221, 221);
    }
    @import '../css/main.css';
</style>