<template>
  <mynav navIndex="-1"></mynav>
  <Price :type="type" :ttype="ttype"></Price>
</template>

<script>
import Price from "../components/price.vue"
export default {
  data(){
    return {
      type: this.$route.params.type,
      ttype: this.$route.params.ttype,
    }
  },
  name: 'Futures',
  components: {Price},
  mounted(){
  }
}
</script>